<template>
  <v-dialog :value="value" width="1200" persistent>
    <v-card height="825" style="background-color:#fafafa;">
      <v-toolbar color="#fafafa" flat dense>
        <v-spacer />
        <v-btn icon large @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="ma-0 px-6">
        <section id="page" class="px-4 pb-4">
          <v-row dense align="center">
            <h2>{{selectedBatch.manufacturer}}</h2>
            <v-spacer />
            <h3 v-if="toggle === 'Posted'">Invoice # {{selectedBatch.invoice_number}}</h3>
          </v-row>
          <v-row dense align="center">
            <h3>{{selectedBatch.batch_comment}}</h3>
            <v-spacer />
            <h3 class="mt-4">Grand Total: {{formatCurrency(selectedBatch.total)}}</h3>
          </v-row>
        </section>
        <section class="py-4 px-6" style="border:1px solid #ccc;background:#fff; height:600px;overflow-y:scroll">
          <section class="py-4 result" v-for="a in adGroups" :key="a.ad_group.id">
            <v-row dense>
              <h3 class="ml-4" style="color:#0D68A5">{{a.ad_group.description}}</h3>
            </v-row>
            <section v-for="(inv, index) in a.invoice_types" :key="index"
              :class="a.invoice_types.length > 1 && index !== 0  ? 'pt-8' : ''">
              <v-row dense>
                <h4 class="ml-4">{{inv.invoice_type}}</h4>
              </v-row>
              <section style="background:#fff;border:1px solid #ccc;">
                <v-row dense justify="center" class="ma-0 px-4 pt-4" style="background:#fafafa;">
                  <v-col cols="4">
                    <strong><small>Item or Feature Description</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small>Cases Sold</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small>Case Allow</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small>Total Allow</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small>Lump-Sum Allow.</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small>Totals</small></strong>
                  </v-col>
                </v-row>
                <v-divider />
                <v-row dense v-for="i in inv.items" :key="i.item_code" class="ma-0 pt-3 px-4">
                  <v-col cols="4">
                    <small v-if="i.item_code !== '000000'">{{i.item_code}} {{i.description}} {{i.pack_size}}</small>
                    <small v-else-if="i.lump_description">{{i.lump_description}}</small>
                    <small v-else>N/A</small>
                  </v-col>
                  <v-col>
                    <small class="ml-6">{{i.cases_sold}}</small>
                  </v-col>
                  <v-col>
                    <small class="ml-4">{{formatCurrency(i.allowance)}}</small>
                  </v-col>
                  <v-col>
                    <small class="ml-1">{{formatCurrency(i.case_allowance)}}</small>
                  </v-col>
                  <v-col>
                    <small class="ml-6">{{formatCurrency(i.lump_allow)}}</small>
                  </v-col>
                  <v-col>
                    <small>{{formatCurrency(i.total)}}</small>
                  </v-col>
                </v-row>
                <v-divider />
                <v-row dense class="ma-0 px-4 pt-4">
                  <v-col cols="4">
                    <h5>{{inv.invoice_type}} Totals:</h5>
                  </v-col>
                  <v-col>
                    <strong><small class="ml-6">{{inv.cases_sold_total}}</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small class="ml-1">{{formatCurrency(inv.case_allow_total)}}</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small class="ml-4">{{formatCurrency(inv.allow_total)}}</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small class="ml-6">{{formatCurrency(inv.lump_total)}}</small></strong>
                  </v-col>
                  <v-col>
                    <strong><small>{{formatCurrency(inv.total)}}</small></strong>
                  </v-col>
                </v-row>
              </section>
            </section>
          </section>
        </section>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'InvoicePreview',

  props: ['value', 'selectedBatch', 'toggle'],

  data () {
    return {
      search: '',
      adGroup: '',
      invoiceType: '',
      adGroups: [],
      items: []
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Item or Feature Description', sortable: true, align: 'start', filterable: true, groupable: false, value: 'description' },
        { text: 'Cases Sold', align: 'center', sortable: true, filterable: true, groupable: false, value: 'cases_sold' },
        { text: 'Cases Allow.', align: 'center', sortable: true, filterable: true, groupable: false, value: 'case_allowance' },
        { text: 'Total Allow.', align: 'center', sortable: true, filterable: true, groupable: false, value: 'allowance' },
        { text: 'Lump-Sum Allow.', align: 'center', sortable: true, filterable: true, groupable: false, value: 'lump_allow' },
        { text: 'Totals', align: 'center', sortable: true, filterable: true, groupable: false, value: 'total' }
      ]
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getInvoiceItems()
        } else {
          this.adGroups = []
        }
      }
    }
  },

  methods: {
    formatCurrency (value) {
      if (!value) {
        return '$0.00'
      }
      return '$' + parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    getInvoiceItems () {
      this.$invoicing.getInvoiceItems(this.selectedBatch.batch_number, this.selectedBatch.invoice_number)
        .then(response => {
          this.adGroups = response.adgroups
        })
    }
  }
}
</script>
