var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, width: "1200", persistent: "" } },
    [
      _c(
        "v-card",
        {
          staticStyle: { "background-color": "#fafafa" },
          attrs: { height: "825" }
        },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "#fafafa", flat: "", dense: "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", large: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-container", { staticClass: "ma-0 px-6" }, [
            _c(
              "section",
              { staticClass: "px-4 pb-4", attrs: { id: "page" } },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "", align: "center" } },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.selectedBatch.manufacturer))]),
                    _c("v-spacer"),
                    _vm.toggle === "Posted"
                      ? _c("h3", [
                          _vm._v(
                            "Invoice # " +
                              _vm._s(_vm.selectedBatch.invoice_number)
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { dense: "", align: "center" } },
                  [
                    _c("h3", [_vm._v(_vm._s(_vm.selectedBatch.batch_comment))]),
                    _c("v-spacer"),
                    _c("h3", { staticClass: "mt-4" }, [
                      _vm._v(
                        "Grand Total: " +
                          _vm._s(_vm.formatCurrency(_vm.selectedBatch.total))
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "section",
              {
                staticClass: "py-4 px-6",
                staticStyle: {
                  border: "1px solid #ccc",
                  background: "#fff",
                  height: "600px",
                  "overflow-y": "scroll"
                }
              },
              _vm._l(_vm.adGroups, function(a) {
                return _c(
                  "section",
                  { key: a.ad_group.id, staticClass: "py-4 result" },
                  [
                    _c("v-row", { attrs: { dense: "" } }, [
                      _c(
                        "h3",
                        {
                          staticClass: "ml-4",
                          staticStyle: { color: "#0D68A5" }
                        },
                        [_vm._v(_vm._s(a.ad_group.description))]
                      )
                    ]),
                    _vm._l(a.invoice_types, function(inv, index) {
                      return _c(
                        "section",
                        {
                          key: index,
                          class:
                            a.invoice_types.length > 1 && index !== 0
                              ? "pt-8"
                              : ""
                        },
                        [
                          _c("v-row", { attrs: { dense: "" } }, [
                            _c("h4", { staticClass: "ml-4" }, [
                              _vm._v(_vm._s(inv.invoice_type))
                            ])
                          ]),
                          _c(
                            "section",
                            {
                              staticStyle: {
                                background: "#fff",
                                border: "1px solid #ccc"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0 px-4 pt-4",
                                  staticStyle: { background: "#fafafa" },
                                  attrs: { dense: "", justify: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _c("strong", [
                                      _c("small", [
                                        _vm._v("Item or Feature Description")
                                      ])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", [_vm._v("Cases Sold")])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", [_vm._v("Case Allow")])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", [_vm._v("Total Allow")])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", [_vm._v("Lump-Sum Allow.")])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", [_vm._v("Totals")])
                                    ])
                                  ])
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _vm._l(inv.items, function(i) {
                                return _c(
                                  "v-row",
                                  {
                                    key: i.item_code,
                                    staticClass: "ma-0 pt-3 px-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      i.item_code !== "000000"
                                        ? _c("small", [
                                            _vm._v(
                                              _vm._s(i.item_code) +
                                                " " +
                                                _vm._s(i.description) +
                                                " " +
                                                _vm._s(i.pack_size)
                                            )
                                          ])
                                        : i.lump_description
                                        ? _c("small", [
                                            _vm._v(_vm._s(i.lump_description))
                                          ])
                                        : _c("small", [_vm._v("N/A")])
                                    ]),
                                    _c("v-col", [
                                      _c("small", { staticClass: "ml-6" }, [
                                        _vm._v(_vm._s(i.cases_sold))
                                      ])
                                    ]),
                                    _c("v-col", [
                                      _c("small", { staticClass: "ml-4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(i.allowance)
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("v-col", [
                                      _c("small", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(i.case_allowance)
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("v-col", [
                                      _c("small", { staticClass: "ml-6" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(i.lump_allow)
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("v-col", [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(_vm.formatCurrency(i.total))
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              }),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0 px-4 pt-4",
                                  attrs: { dense: "" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _c("h5", [
                                      _vm._v(
                                        _vm._s(inv.invoice_type) + " Totals:"
                                      )
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", { staticClass: "ml-6" }, [
                                        _vm._v(_vm._s(inv.cases_sold_total))
                                      ])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(
                                              inv.case_allow_total
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", { staticClass: "ml-4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(inv.allow_total)
                                          )
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", { staticClass: "ml-6" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(inv.lump_total)
                                          )
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c("strong", [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(_vm.formatCurrency(inv.total))
                                        )
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }